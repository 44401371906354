<template>
    <FullWidth>
        <div class="py-10 container">
            <div class="row">
                <div class="col-md-2">
                    <div class="card card-custom">
                        <div class="card-header">
                            <div class="card-title w-100 mr-0">
                                <div class="card-label w-100 text-center text-uppercase mr-0">My Profile</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="p-5 text-center" style="margin: -13px; margin-bottom: 15px; background: #f2fffa; border-bottom: 1px solid #EBEDF3;">
                                <i class="far fa-user-circle fa-4x mb-3"></i><br/>
                                <h4>{{userInfo.name}}</h4>
                                <span class="text-muted">{{userInfo.mobile_number}}</span>
                            </div>
                            <ul class="navi navi-hover">
                                <router-link :to="'/profile/dashboard/'">
                                    <li class="navi-item">
                                        <a class="navi-link px-2" ><i class="fas fa-tachometer-alt text-aqua text-left" style="width: 30px;"></i> Profile Dashboard</a>
                                    </li>
                                </router-link>
                                <router-link :to="'/'">
                                    <li class="navi-item">
                                        <a class="navi-link px-2"><i class="fas fa-ticket-alt text-aqua text-left" style="width: 30px;"></i> Ticket Booking</a>
                                    </li>
                                </router-link>
                                <router-link :to="'/profile/tickets/'">
                                    <li class="navi-item">
                                        <a class="navi-link px-2"><i class="fas fa-list-alt text-aqua text-left" style="width: 30px;"></i> My Tickets</a>
                                    </li>
                                </router-link>
                                <router-link :to="'/profile/edit/'">
                                    <li class="navi-item">
                                        <a class="navi-link px-2" ><i class="fas fa-user-edit text-aqua text-left" style="width: 30px;"></i> Profile Edit</a>
                                    </li>
                                </router-link>
                                <router-link :to="'/login'">
                                  <li class="navi-item">
                                    <a class="navi-link px-2" ><i class="fa fa-fw fa-power-off text-aqua text-left" style="width: 30px;"></i> Sign Out</a>
                                  </li>
                                </router-link>
<!--                                <li class="navi-item">
                                    <a href="#" class="navi-link px-2" @click="onLogout"><i class="fa fa-fw fa-power-off text-aqua text-left" style="width: 30px;"></i> Sign Out</a>
                                </li>-->
                                <h4 class="pt-10 text-center text-aqua">SUPPORT CENTER<br/><b class="text-info">{{getCompanyInfo.website_content.contact_number}}</b></h4>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-10">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </FullWidth>
</template>
<style scoped>
    .router-link-exact-active .navi-item {
        background: #0790E8 !important;
    }
    .router-link-exact-active .navi-item .navi-link {
        color: #ffffff !important;
    }
</style>
<script>
    import {mapGetters} from 'vuex';
    import {AUTH_LOGOUT} from "@/core/services/store/authentication.module";
    import profileService from "@/core/services/models/profile.service";
    import FullWidth from "@/view/content/FullWidth";
    export default {
        name: "profile",
        components : {
            FullWidth,
        },
        data() {
            return {
                selectedProfileTab:'profileEdit'
            };
        },
        methods: {
            setProfileTab(activeTab){
                //console.log(activeTab);
                this.selectedProfileTab = activeTab;
            },
            onLogout() {
                profileService.logOut(data =>{
                    this.$store
                        .dispatch(AUTH_LOGOUT)
                        .then(() => this.$router.push({ name: "dashboard" }));
                })
            },
        },
        computed: {
            ...mapGetters(['userInfo','isLoggedIn','getCompanyInfo'])
        }
    };
</script>

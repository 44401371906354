<template>
    <div class="d-flex flex-column-fluid">
        <div class="container-fluid">
            <div class="d-lg-flex flex-row-fluid">
                <div class="content-wrapper flex-row-fluid">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FullWidth"
    }
</script>

<style scoped>

</style>
